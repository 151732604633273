import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import InputSelectTemplate from '../../../InputComponents/InputSelectTemplate.js'
import InputDropdownInvoices from '../../../InputComponents/InputDropdownInvoices.js'
import TableHeaderInvoicePaid2 from '../../Misc/TableHeaderInvoicePaid2.js'
import AccordionComponentInvoice from '../../../AccordionComponents/AccordionComponentInvoice.js'

// Import Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'

library.add(faShareSquare)


const GContentInvoicePaid = styled.div`
    // 📰
    display: grid;
    grid-template-rows: minmax(0.2fr, 5fr) 0.5fr 0.5fr 5fr 0.2fr;
    grid-template-columns: 1fr;
    grid-template-areas:
    "g_filters"
    "g_table_header"
    "g_shipment_table"
    "g_shipment_pagination";
`;


const GFilters = styled.div`
    grid-area: g_filters; // 🗺
    margin-top: 15px;
    margin-bottom: 15px;
`;

const ExportResults = styled.div`
    grid-area: sg_filter_3; // 🗺
    align-self: end;
    justify-self: end;
    text-align: right;
    color: #666;
    font-weight: 600;
    font-size: 18px;
    a {
      color: #0033a1 !important;
      text-decoration: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 300;
    }
`;

const SgFilters = styled.div`
    margin: 0 auto;
    max-width: 1360px;
    // 📰
    display: grid;
    grid-template-rows: minmax(1fr, 1fr) 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "sg_filter_1 sg_filter_2 sg_filter_3";

    .InputSelectTemplate {
      grid-area: sg_filter_1; // 🗺
    }
    .InputDropdownTemplate {
      grid-area: sg_filter_2; // 🗺
      justify-self: start;
    }
`;


const GTableHeader = styled.div`
    grid-area: g_table_header; // 🗺
    margin-bottom: 5px;
`
;

const GQuotesTable = styled.div`
    grid-area: g_shipment_table; // 🗺
`;

const SgQuotesTable = styled.div`
    margin: 0 auto;
    max-width: 1360px;
    display: grid;
    grid-template-rows: repeat(10, 0.5fr);
    grid-template-columns: 1fr;
    .AccordionComponentQuotes {
      grid-column-start: 1;
      grid-column-end: 10; // 🗺
    }
`;


const GShipmentPagination = styled.div`
    grid-area: g_shipment_pagination; // 🗺
`;

const SgShipmentPagination = styled.div`

    // 📰
    display: grid;
    margin: 0 auto;
    max-width: 1360px;
    margin-top: 40px;
    margin-bottom: 40px;
    grid-template-rows: minmax(0.2fr, 1fr) 1fr 0.2fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    ". sg_item_per_page sg_pagination"
    ". sg_item_per_page sg_page_counter";

    .sg-items-per-page {
      grid-area: sg_item_per_page; // 🗺
      justify-self: center;
      // 🎨
      font-size: 12px;
      color:#768287;
    }
    .sg-pagination {
      grid-area: sg_pagination; // 🗺
      justify-self: end;
      .csg-page {
        font-size: 14px;
        color: #0033a1;
        padding-left: 5px;
        padding-right: 5px;
        border: solid 1px #fff;
        background-color: transparent;
        border-radius: 3px;
        &:hover {
          color: #fff;
          border: solid 1px #fff;
          background-color: #26c1fb;
        }
      }
      .csg-page.active {
        color: #0033a1;
        border: solid 1px #0033a1;
        background-color: transparent;
      }
    }
    .sg-page-counter {
      grid-area: sg_page_counter; // 🗺
      justify-self: end;
      // 🎨
      font-size: 12px;
      color:#768287;
    }
`;


const EchoShipContentGrid = () => {
  return (

    <GContentInvoicePaid>

        <GFilters>
            <SgFilters>
                <InputSelectTemplate 
                  labelTitle="Search for Paid Invoices"
                  placeholder="Search by Invoice#, PO#, Load#"
                />
                <InputDropdownInvoices 
                  labelTitle="Sort Paid Invoices By"
                />
                <ExportResults>
                16 Paid Invoices <br />
                <a href="/">Export Results</a> <FontAwesomeIcon icon="share-square"  size="xs" style={{ color: '#0033a1' }} />
                </ExportResults>
            </SgFilters>
        </GFilters>

        <GTableHeader>
            <TableHeaderInvoicePaid2></TableHeaderInvoicePaid2>
        </GTableHeader>
        <GQuotesTable>
            <SgQuotesTable>

                <AccordionComponentInvoice
                  invoiceNumber="35010633-1"
                  poNumber="N/A"
                  orderNumber="N/A"
                  originCity="Anaheim, CA"
                  originZip="92805"
                  destinationCity="Sf, CA"
                  destinationZip="90210"
                  invoiceDate="Mon, Apr 1"
                  paymentDate="Tue, Apr 2"
                  amountPaid="$565.34"
                  paymentInfo="Valid Visa...2224"
                />

                <AccordionComponentInvoice
                  invoiceNumber="35010064-1"
                  poNumber="N/A"
                  orderNumber="N/A"
                  originCity="Houston, TX"
                  originZip="77063"
                  destinationCity="Doral, FL"
                  destinationZip="33172"
                  invoiceDate="Thu, Mar 21"
                  paymentDate="Mon, Apr 1"
                  amountPaid="$1.00"
                  paymentInfo="Echo AR"
                />

                <AccordionComponentInvoice
                  invoiceNumber="34877808-1"
                  poNumber="N/A"
                  orderNumber="N/A"
                  originCity="Houston, TX"
                  originZip="77063"
                  destinationCity="Jefferson City, MO"
                  destinationZip="65109"
                  invoiceDate="Wed, Feb 27"
                  paymentDate="Mon, Mar 19"
                  amountPaid="$116.78"
                  paymentInfo="E C H o Sh I P...2221"
                />

                <AccordionComponentInvoice
                  invoiceNumber="34743684-1"
                  poNumber="N/A"
                  orderNumber="N/A"
                  originCity="Houston, TX"
                  originZip="77063"
                  destinationCity="Herndon, VA"
                  destinationZip="20171"
                  invoiceDate="Sat, Feb 16"
                  paymentDate="Tue, Mar 19"
                  amountPaid="$147.95"
                  paymentInfo="tyler echeeck...3658"
                />

                

            </SgQuotesTable>
        </GQuotesTable>

        <GShipmentPagination>
            <SgShipmentPagination>

                <div className="sg-items-per-page">
                  <div className="es-c-items-per-page">
                    <div className="es-cg-items-per-page">
                      Items per page: <a href="#">10</a> |  <a href="#">25</a> | <a href="#">50</a>
                    </div>
                  </div>
                </div>

                <div className="sg-pagination">
                  <a className="csg-page active">1</a>
                  <a className="csg-page">2</a>
                  <a className="csg-page">3</a>
                  <a className="csg-page">4</a>
                  <a className="csg-page">5</a>
                  <a className="csg-page">&gt;</a>
                  <a className="csg-page">&gt;&gt;</a>
                </div>

                <div className="sg-page-counter">
                  Page 1 of 310
                </div>

            </SgShipmentPagination>
        </GShipmentPagination>


    </GContentInvoicePaid>

  )
}

export default EchoShipContentGrid;